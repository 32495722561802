<template>
  <v-container fluid class="pa-0">
    <v-row justify="center" class="mt-5 py-5">
      <v-col cols="10">
        <h1
          class="text-center font-weight-bold"
          :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'"
        >
          AVISO LEGAL
        </h1>
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <!-- INTRODUCCIÓN -->
      <v-col cols="10" offset="1" md="8" offset-md="2" class="text-justify">
        <p><br /></p>
        <p>
          El aviso sobre ciertos t&eacute;rminos y condiciones de
          navegaci&oacute;n que a continuaci&oacute;n se presenta (el
          &ldquo;Aviso Legal&rdquo;) constituye un acuerdo &iacute;ntegro
          entre&nbsp;PLATAFORMA DIGITAL WE CHAMBER S.A.P.I. de C.V., sus
          filiales y/o subsidiarias y/o sus partes relacionadas (&ldquo;We
          Chamber&rdquo;), con domicilio en Blvd. Campestre 403 Piso 1, Col.
          Jardines del Moral C.P. 37160 en Le&oacute;n, Guanajuato,
          direcci&oacute;n electr&oacute;nica:&nbsp;<a
            href="http://www.wechamber.mx"
            >www.wechamber.mx</a
          >
          (el &ldquo;Sitio&rdquo;) y con correo electr&oacute;nico de
          contacto&nbsp;<a href="mailto:contacto@wechamber.mx"
            >contacto@wechamber.mx</a
          >
          (el &ldquo;Correo Electr&oacute;nico&rdquo;); y cualquier usuario del
          Sitio.&nbsp;La utilizaci&oacute;n del Sitio, por parte de cualquier
          persona, le atribuye la calidad de usuario (el &ldquo;Usuario&rdquo;)
          y ello implica su adhesi&oacute;n plena e incondicional a este Aviso
          Legal. En consecuencia, es responsabilidad &uacute;nica y exclusiva
          del Usuario, leer previamente este Aviso Legal y sus modificaciones
          correspondientes, cada vez que accede al Sitio. Si, en cualquier
          momento, el Usuario no estuviera de acuerdo total o parcialmente con
          el presente Aviso Legal, deber&aacute; abstenerse inmediatamente de
          usar el Sitio.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          ACCESO, UTILIZACI&Oacute;N Y ESTANCIA EN EL SITIO
        </p>
        <p>
          El acceso y utilizaci&oacute;n del Sitio tiene car&aacute;cter
          gratuito para los Usuarios. El Usuario no estar&aacute; obligado a
          registrarse o inscribirse en el Sitio. Los servicios ofrecidos a
          trav&eacute;s del Sitio est&aacute;n dirigidos a toda persona con
          capacidad jur&iacute;dica para obligarse de conformidad con los
          t&eacute;rminos y condiciones aplicables una vez que el Usuario decide
          crear un usuario y contrase&ntilde;a para transaccionar mediante el
          Sitio.&nbsp;El Usuario es el &uacute;nico responsable frente a We
          Chamber, y cualquier tercero, respecto de su conducta al acceder,
          consultar y proporcionar informaci&oacute;n al Sitio y de las
          consecuencias que se puedan derivar de una utilizaci&oacute;n, con
          fines o efectos il&iacute;citos o contrarios al objeto del Sitio, su
          contenido, elaborado o no por We Chamber, publicado o no bajo su
          nombre de forma oficial; as&iacute; como aquellas consecuencias que se
          puedan derivar de la utilizaci&oacute;n contraria al contenido del
          Aviso Legal que sea lesiva de los intereses o derechos de terceros, o
          que de cualquier forma pueda da&ntilde;ar, inutilizar o deteriorar el
          Sitio e impedir el normal disfrute de otros Usuarios.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">OBJETO</p>
        <p>
          We Chamber&nbsp;pone a disposici&oacute;n del Usuario el Sitio, ya sea
          que se trate de un sitio patrocinado, administrado por intermedio de
          un tercero o alojado por un tercero,&nbsp;facilitando el acceso a los
          Usuarios a informaci&oacute;n diversa proporcionada por We Chamber o
          por personas vinculadas a dicha informaci&oacute;n de manera directa o
          indirecta (los &ldquo;Contenidos&rdquo;). El Usuario reconoce que el
          uso del Sitio no le confiere ning&uacute;n derecho de propiedad sobre
          el mismo, o cualquiera de sus elementos o Contenidos. We Chamber se
          reserva el derecho a modificar, total o parcialmente, en cualquier
          momento y sin aviso previo, la presentaci&oacute;n,
          configuraci&oacute;n, informaci&oacute;n, Contenidos y en general
          cualquier parte o aspecto relacionado directa o indirectamente con el
          Sitio.
        </p>
        <p><br /></p>
        <p>
          El Usuario reconoce que las descripciones de los servicios expuestos
          en el Sitio se realizan de forma ilustrativa, y tendr&aacute;n el
          alcance y las especificaciones comunes, b&aacute;sicas y simples que
          regularmente el mercado ofrece, salvo cuando dichos alcances y
          especificaciones se detallan en el Sitio o en los t&eacute;rminos y
          condiciones que regulen una transacci&oacute;n comercial entre el
          Usuario y We Chamber. De igual forma, reconoce que la
          informaci&oacute;n contenida y/o publicada a trav&eacute;s del Sitio,
          tiene fines meramente informativos sobre los servicios que proporciona
          We Chamber.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          CONTENIDOS<br />El Usuario se compromete a:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            <p>
              utilizar el Sitio y sus Contenidos de acuerdo a las leyes
              aplicables y de orden p&uacute;blico, absteni&eacute;ndose de
              realizar acto que menoscabe, deteriore, inutilice o da&ntilde;e la
              imagen y/o informaci&oacute;n divulgada por We Chamber o de alguna
              manera lesione derechos o intereses de terceras personas,
              vinculadas directa o indirectamente a &eacute;ste;&nbsp;
            </p>
          </li>
          <li>
            <p>
              no copiar, difundir, modificar, reproducir, distribuir o utilizar
              de manera alguna con o sin fines de lucro los contenidos y los
              elementos utilizados en el Sitio, a menos que se cuente con la
              autorizaci&oacute;n expresa y por escrito de We Chamber;&nbsp;
            </p>
          </li>
          <li>
            <p>
              no modificar o manipular las marcas, logotipos, avisos
              comerciales, nombres comerciales y signos distintivos en general
              de We Chamber, del Sitio o de las personas vinculadas con We
              Chamber (salvo que cuente con su autorizaci&oacute;n por
              escrito);&nbsp;
            </p>
          </li>
          <li>
            <p>
              suprimir, eludir o modificar los Contenidos y los elementos
              utilizados en el Sitio, as&iacute; como los dispositivos
              t&eacute;cnicos de protecci&oacute;n, o cualquier mecanismo o
              procedimiento establecido en el Sitio.
            </p>
          </li>
        </ol>
        <p><br /></p>
        <p>
          Queda excluida de los puntos anteriores, aquella informaci&oacute;n
          generada a trav&eacute;s del Sitio para uso y manejo del Usuario,
          misma que podr&aacute; ser impresa y/o copiada para los intereses que
          m&aacute;s convengan al mismo. En caso de que el Usuario sea una
          persona moral, se apegar&aacute; a lo dispuesto por el art&iacute;culo
          148, fracci&oacute;n IV de la Ley Federal del Derecho de Autor.
        </p>
        <p><br /></p>
        <p>
          El Usuario reconoce y acepta que el uso del Sitio y de los Contenidos,
          es bajo su exclusiva y estricta responsabilidad, por lo que We Chamber
          no ser&aacute;, en ning&uacute;n momento y bajo ninguna circunstancia,
          responsable por cualquier desperfecto o problema que se presente en el
          equipo de c&oacute;mputo (hardware) o programas de c&oacute;mputo
          (software) que utilice el Usuario para acceder o navegar en cualquier
          parte del Sitio.
        </p>
        <p><br /></p>
        <p>
          We Chamber&nbsp;tiene derecho a realizar, durante intervalos
          temporales definidos, campa&ntilde;as promocionales para promover el
          registro de nuevos miembros en su Sitio. We Chamber se reserva el
          derecho de modificar los t&eacute;rminos y condiciones de las compras
          o contrataciones de servicios, as&iacute; como de proceder a la
          exclusi&oacute;n de cualquiera de los mismos.
        </p>
        <p><br /></p>
        <p>
          We Chamber declara que todos los Contenidos, y los elementos
          utilizados en el Sitio, se encuentran debidamente registrados y
          protegidos bajo las autoridades y leyes correspondientes en
          M&eacute;xico. El Usuario se obliga a respetar todos los derechos
          contenidos en el Aviso de Derecho de Autor establecido en el Sitio.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          SITIOS Y CONTENIDOS AJENOS a We Chamber
        </p>
        <p>
          We Chamber podr&aacute; hacer uso de su derecho de publicaci&oacute;n
          de cualquier material informativo y/o de sitios o subsitios propiedad
          de terceros, vinculados o no a We Chamber, que considere de
          inter&eacute;s para los Usuarios. No obstante lo anterior, We Chamber
          se deslinda de toda responsabilidad, del acceso y/o uso que realice el
          Usuario de la informaci&oacute;n ah&iacute; contenida y/o del uso,
          origen y destino de la informaci&oacute;n que se desprenda de ligas
          distintas (v&iacute;nculo, hiperv&iacute;nculo,&nbsp;link).
        </p>
        <p>
          Toda publicaci&oacute;n realizada dentro del Sitio, por parte del
          Usuario, no genera obligaci&oacute;n de pago ante terceros por
          raz&oacute;n de promoci&oacute;n, publicaci&oacute;n y/o manejo de
          informaci&oacute;n y/o imagen, a menos que se cuente con un contrato
          previamente firmado con We Chamber.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          RESPONSABILIDAD RESPECTO A LOS CONTENIDOS
        </p>
        <p>
          We Chamber no asume responsabilidad alguna derivada, de manera
          enunciativa m&aacute;s no limitativa de: (i) la utilizaci&oacute;n que
          el Usuario pueda hacer de los materiales de este Sitio, o de los
          Contenidos, o de los sitios web de enlace, ya sean prohibidos o
          permitidos, en infracci&oacute;n de los derechos de propiedad
          intelectual y/o industrial de contenidos de la web o de terceros; (ii)
          los eventuales da&ntilde;os y perjuicios al Usuario causados por un
          funcionamiento normal o anormal de las herramientas de
          b&uacute;squeda, de la organizaci&oacute;n o la localizaci&oacute;n de
          los Contenidos y/o acceso al Sitio y, en general, de los errores o
          problemas que se generen en el desarrollo o instrumentaci&oacute;n de
          los elementos t&eacute;cnicos que el Sitio facilite al Usuario; (iii)
          los contenidos de aquellas p&aacute;ginas a las que el Usuario pueda
          acceder desde enlaces incluidos en el Sitio, ya sean autorizados o no;
          (iv) los actos u omisiones de terceros, independientemente de la
          relaci&oacute;n que dichos terceros pudieran tener con We Chamber; (v)
          el acceso de menores de edad a los Contenidos, as&iacute; como el
          env&iacute;o de informaci&oacute;n personal que estos pudieran
          realizar; (vi) las comunicaciones o di&aacute;logos en el transcurso
          de los debates, foros, chats y comunidades virtuales que se organicen
          a trav&eacute;s de o en torno al Sitio de enlace, ni
          responder&aacute;, por tanto, de los eventuales da&ntilde;os y
          perjuicios que sufra el Usuario a consecuencia de dichas
          comunicaciones y/o di&aacute;logos; etc.&nbsp;
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          RESPONSABILIDAD RESPECTO A FALLAS DE LAS TECNOLOG&Iacute;AS DE
          INFORMACI&Oacute;N Y COMUNICACI&Oacute;N&nbsp;
        </p>
        <p>
          We Chamber no ser&aacute; responsable, en forma alguna, cuando se
          produzcan:&nbsp;
        </p>
        <p><br /></p>
        <ol>
          <li>
            <p>
              errores o retrasos en el acceso al Sitio a la hora de introducir
              los datos en el formulario de solicitud;&nbsp;
            </p>
          </li>
          <li>
            <p>
              la lentitud o imposibilidad de recepci&oacute;n por parte de los
              destinatarios de la confirmaci&oacute;n de la solicitud o
              cualquier anomal&iacute;a que pueda surgir por incidencias, ya
              sean debidas a problemas en la red Internet, caso fortuito o
              fuerza mayor o cualquier u otra contingencia imprevisible y ajena
              a We Chamber;&nbsp;
            </p>
          </li>
          <li>
            <p>
              fallos o incidencias que pudieran producirse en las
              comunicaciones, ya sea borr&aacute;ndose o por transmisiones
              incompletas, de manera que no se garantiza que los servicios del
              Sitio est&eacute;n constantemente operativos;&nbsp;
            </p>
          </li>
          <li>
            <p>
              de los errores o da&ntilde;os producidos al Sitio por un mal uso
              del servicio por parte del Usuario u otros usuarios;&nbsp;
            </p>
          </li>
          <li>
            <p>
              de la no operatividad o problemas en la direcci&oacute;n de correo
              electr&oacute;nico facilitada por el Usuario para el env&iacute;o
              de la confirmaci&oacute;n de la solicitud realizada.&nbsp;
            </p>
          </li>
        </ol>
        <p><br /></p>
        <p>
          En todos los casos expuestos, el compromiso de We Chamber ser&aacute;
          realizar su mejor esfuerzo para solucionar los problemas que
          est&eacute;n a su alcance, y ofrecer todo el apoyo necesario al
          Usuario para llegar a una soluci&oacute;n r&aacute;pida y
          satisfactoria de la incidencia.&nbsp;
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          EXCLUSI&Oacute;N DE GARANT&Iacute;AS Y DE RESPONSABILIDAD
        </p>
        <p>
          El Usuario es el &uacute;nico responsable del uso que haga al Sitio y
          su Contenido. El Usuario reconoce que la informaci&oacute;n del SItio
          y de los servicios de We Chamber se proporcionan &ldquo;como
          est&aacute;n&rdquo;, sin ninguna garant&iacute;a expresa o
          impl&iacute;cita de comerciabilidad o de aptitud para un fin
          determinado. We Chamber&nbsp;no garantiza la precisi&oacute;n ni la
          integridad de la informaci&oacute;n, textos, gr&aacute;ficos, enlaces
          u otros elementos contenidos en el Sitio o Contenido. We
          Chamber&nbsp;no garantiza la operaci&oacute;n ininterrumpida o libre
          de todo error del Sitio y/o su Contenido. Puesto que toda la
          informaci&oacute;n referida en el Sitio y su Contenido se encuentra en
          la nube, We Chamber no controla ni garantiza la ausencia de virus en
          los Contenidos, ni la ausencia de otros elementos en los Contenidos
          que puedan producir alteraciones en el sistema inform&aacute;tico del
          Usuario (software y/o hardware) o en los documentos
          electr&oacute;nicos almacenados en su sistema
          inform&aacute;tico.&nbsp;
        </p>
        <p><br /></p>
        <p>
          Todo material descargado u obtenido de un modo distinto al previsto en
          el Sitio, ser&aacute; bajo responsabilidad y riesgo &uacute;nico del
          Usuario, respecto de los da&ntilde;os que pudiera causar en el sistema
          inform&aacute;tico del dispositivo a trav&eacute;s del cual realice su
          conexi&oacute;n y/o la p&eacute;rdida de datos que derive de la
          descarga de ese material. En ning&uacute;n caso, ni We Chamber&nbsp;ni
          sus proveedores ser&aacute;n responsables de da&ntilde;o alguno
          derivado del uso del Sitio o Contenido, o de no poder usarlos&nbsp;(EN
          PARTICULAR, SIN LIMITACI&Oacute;N ALGUNA, DE LOS DA&Ntilde;OS DIRECTOS
          O INDIRECTOS, MORALES, INCIDENTALES, EXCESIVOS, REMOTOS Y/O
          EVENTUALES, PERJUICIOS, LUCRO CESANTE, INTERRUPCI&Oacute;N DE LA
          ACTIVIDAD COMERCIAL O P&Eacute;RDIDA DE INFORMACI&Oacute;N O DATOS Y/O
          INFRACCIONES DE SEGURIDAD), a&uacute;n cuando se hubiera advertido a
          We Chamber de dicha posibilidad.&nbsp;
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          RETIRO DEL ACCESO AL SITIO Y/O A LOS CONTENIDOS
        </p>
        <p>
          We Chamber se reserva el derecho a negar o retirar el acceso al Sitio,
          o sus Contenidos, en cualquier momento, sin responsabilidad alguna
          para We Chamber y sin previo aviso al Usuario o usuarios que incumplan
          de manera total o parcial con las condiciones aqu&iacute; establecidas
          y/o que realicen acciones o actos tendientes a:&nbsp;
        </p>
        <p><br /></p>
        <ol>
          <li>
            <p>
              &ldquo;asediar&quot; o de otra manera acosar o molestar a otros
              usuarios;
            </p>
          </li>
          <li>
            <p>
              hacerse pasar como representante o empleado de We Chamber,
              realizando declaraciones falsas o de otro modo err&oacute;neas de
              su vinculaci&oacute;n con We Chamber;
            </p>
          </li>
          <li>
            <p>
              recopilar o almacenar datos personales de otros Usuarios en
              relaci&oacute;n con la conducta y las actividades
              prohibidas;&nbsp;
            </p>
          </li>
          <li>
            <p>
              falsificar encabezados o manipular identificadores del Sitio, con
              la finalidad de ocultar el origen de los Contenidos;
            </p>
          </li>
          <li>
            <p>
              cargar, publicar, enviar por correo electr&oacute;nico, transmitir
              o proporcionar de otro modo, cualquier contenido respecto del cual
              no tenga derecho a transmitir, en virtud de los t&eacute;rminos
              contenidos en la Ley Federal de Protecci&oacute;n a la Propiedad
              Industrial (&ldquo;LFPPI&rdquo;), la Ley Federal del Derecho de
              Autor (&ldquo;LFDA&rdquo;), y la Ley Federal de Protecci&oacute;n
              de Datos Personales en Posesi&oacute;n de Particulares
              &nbsp;(&ldquo;LFPDPPP&rdquo;) o de relaciones contractuales
              protegidos por convenios de confidencialidad y no
              divulgaci&oacute;n;&nbsp;
            </p>
          </li>
          <li>
            <p>
              cargar, publicar, enviar por correo electr&oacute;nico, transmitir
              o proporcionar de otro modo, materiales que contengan virus
              inform&aacute;ticos o cualquier otro c&oacute;digo
              inform&aacute;tico, archivos o programas dise&ntilde;ados para
              interrumpir, destruir o limitar la funcionalidad del software,
              hardware o de equipos de telecomunicaciones conectados al
              Sitio;&nbsp;
            </p>
          </li>
          <li>
            <p>
              hacer uso del Sitio de una manera que pudiera da&ntilde;ar,
              deshabilitar, recargar o alterar los servidores de We Chamber o
              las conexiones de redes;
            </p>
          </li>
          <li>
            <p>
              ignorar requisitos, procedimientos, pol&iacute;ticas o normas de
              redes conectadas al Sitio que pudieran interferir con el uso y
              goce del Sitio por parte de cualquier tercero; y
            </p>
          </li>
          <li>
            <p>
              acceder de manera no autorizada a cuentas, sistemas
              inform&aacute;ticos o redes conectadas a los servidores de We
              Chamber, a trav&eacute;s de ataques propios de piratas
              inform&aacute;ticos, el descifrado de contrase&ntilde;as o
              cualquier otro m&eacute;todo para obtener o tratar de obtener
              materiales o informaci&oacute;n con cualquier medio que no se
              ofrece intencionalmente a trav&eacute;s del Sitio.&nbsp;
            </p>
          </li>
        </ol>
        <p><br /></p>
        <p>
          El Usuario acepta indemnizar y mantener en paz y a salvo a We
          Chamber&nbsp;y sus funcionarios, agentes, empleados, socios,
          proveedores y licenciantes frente a cualquier reclamo o demanda,
          as&iacute; como a cubrir los honorarios razonables de abogados, que
          promueva cualquier tercero en contra de We Chamber&nbsp;a causa del
          contenido que el Usuario env&iacute;e, publique, transmita o
          proporcione de un modo distinto al previsto en el Sitio.&nbsp;Lo
          anterior, sin perjuicio del derecho de We Chamber de&nbsp;realizar las
          acciones judiciales necesarias para reclamar los da&ntilde;os y
          perjuicios que dichas acciones por parte del Usuario pudieran
          causarle.&nbsp;
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          PROPIEDAD&nbsp;INDUSTRIAL Y DERECHO DE AUTOR
        </p>
        <p>
          We Chamber autoriza al Usuario a utilizar el Sitio, exclusivamente
          bajo los t&eacute;rminos aqu&iacute; expresados, sin que ello implique
          que concede al Usuario licencia o autorizaci&oacute;n alguna, o
          alg&uacute;n tipo de derecho distinto al antes mencionado, respecto de
          la Propiedad Industrial y Derecho de Autor de We Chamber,
          &nbsp;entendi&eacute;ndose como ello: todas las marcas registradas y/o
          usadas en M&eacute;xico o en el extranjero por We Chamber; todo
          derecho sobre invenciones (patentadas o no), dise&ntilde;os
          industriales, modelos de utilidad, informaci&oacute;n confidencial,
          nombres comerciales, secretos industriales, avisos comerciales,
          reservas de derechos, nombres de dominio; as&iacute; como todo tipo de
          derechos patrimoniales sobre obras y creaciones protegidas por
          derechos de autor y dem&aacute;s formas de propiedad industrial o
          intelectual reconocida o que lleguen a reconocer las leyes
          correspondientes.
        </p>
        <p><br /></p>
        <p>
          El Usuario reconoce y acepta que We Chamber es leg&iacute;timo
          propietario, o tiene los derechos necesarios, sobre el Sitio,
          incluidos los nombres comerciales de We Chamber, marcas comerciales,
          marcas de servicio, logotipos, nombres de dominio y otras
          caracter&iacute;sticas distintivas de la marca contenidas en ellos
          (las &ldquo;Marcas Registradas de We Chamber&rdquo;),
          independientemente de que esos derechos est&eacute;n registrados o no,
          y de cualquier lugar del mundo en el que puedan existir esos derechos,
          y que est&aacute;n protegidos por las leyes y tratados internacionales
          sobre protecci&oacute;n a la propiedad industrial y derecho de autor.
          Por lo anterior, el Usuario acepta que las Marcas Registradas de We
          Chamber no podr&aacute;n ser objeto de copia, reproducci&oacute;n,
          modificaci&oacute;n, publicaci&oacute;n, carga, env&iacute;o,
          transmisi&oacute;n o distribuci&oacute;n en modo alguno. Salvo
          indicaci&oacute;n expresa en contrario en este documento, We Chamber
          no concede al Usuario ning&uacute;n derecho expreso ni
          impl&iacute;cito en virtud de patentes, derecho de autor, marcas
          comerciales o informaci&oacute;n de secretos industriales. El Usuario
          reconoce y conviene que el Sitio, as&iacute; como todos los
          dise&ntilde;os del mismo, son y, ser&aacute;n en todo momento,
          propiedad de We Chamber.
        </p>
        <p>&nbsp;</p>
        <p>
          Retroalimentaci&oacute;n. En caso de que el Usuario proporcione
          alg&uacute;n comentario a We Chamber respecto de la funcionalidad y el
          rendimiento del Sitio (incluida la identificaci&oacute;n de posibles
          errores y mejoras), en este acto, el Usuario autoriza a We Chamber
          para que haga uso, sin restricci&oacute;n, de todos los derechos,
          t&iacute;tulos e intereses sobre los comentarios expresados. Lo
          anterior, sin que ello se considere como un derecho moral del Usuario
          para requerir participaci&oacute;n o retribuci&oacute;n monetaria
          alguna, o restricci&oacute;n en el uso de dichos comentarios para su
          explotaci&oacute;n por parte de We Chamber.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">DATOS DE CAR&Aacute;CTER PERSONAL</p>
        <p>
          En cumplimiento a los t&eacute;rminos previstos en la LFPDPPP, We
          Chamber,&nbsp;como responsable del tratamiento de sus datos
          personales, hace del conocimiento del Usuario que la
          informaci&oacute;n que el Usuario provea en este Sitio ser&aacute;
          tratada de conformidad con lo indicado en el Aviso de Privacidad
          contenido en el Sitio.&nbsp;Para utilizar o gozar de algunos de los
          Contenidos, es necesario que el Usuario proporcione previamente a We
          Chamber ciertos datos de car&aacute;cter personal (&ldquo;Datos
          Personales&rdquo;).&nbsp;
        </p>
        <p><br /></p>
        <p>
          Al acceder al Sitio, o a cualquiera de los Contenidos en que los Datos
          Personales son requeridos, el Usuario est&aacute; autorizando a We
          Chamber a realizar an&aacute;lisis y estudios con base en ellos. El
          Usuario se obliga a proporcionar Datos Personales verdaderos y
          fidedignos. En caso de que el Usuario diera informaci&oacute;n falsa o
          confusa, We Chamber no asume responsabilidad alguna de los resultados
          que dichos actos ocasionen al Usuario, teniendo la facultad de negar
          el acceso al Sitio y sus Contenidos, sin perjuicio de que pueda
          requerir las indemnizaciones a que hubiere lugar.&nbsp;
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">INDICADORES DE DATOS</p>
        <p>
          La informaci&oacute;n que el Usuario provea en el Sitio, real o
          hist&oacute;rica, se procesa y ordena, para que genere indicadores de
          datos, mismos que We Chamber podr&aacute; usar para tomar decisiones
          pertinentes a su negocio, siempre de manera estad&iacute;stica y no
          individualizada. El Usuario, en este acto, autoriza el acceso a We
          Chamber a la informaci&oacute;n proporcionada y generada en el Sitio,
          en t&eacute;rminos del presente documento y del Aviso de Privacidad.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">USO DE COOKIES</p>
        <p>
          We Chamber informa&nbsp;al Usuario que, mediante el uso de cookies y
          tecnolog&iacute;as similares, busca: i) garantizar la mejor
          experiencia posible en el Sitio; y ii) proporcionar al Usuario
          informaci&oacute;n sobre sus preferencias de servicios y de mercadeo,
          ayud&aacute;ndolo as&iacute; a obtener la informaci&oacute;n adecuada.
          En caso de que el Usuario requiera de mayor informaci&oacute;n
          respecto al uso de cookies y tecnolog&iacute;as similares, We
          Chamber&nbsp;pone a su disposici&oacute;n la Pol&iacute;tica de Uso de
          Cookies.&nbsp;
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          COMPATIBILIDAD DE LOS DISPOSITIVOS ELECTR&Oacute;NICOS&nbsp;
        </p>
        <p>
          El Usuario ser&aacute; responsable de obtener los dispositivos o
          hardware que sean compatibles con el Sitio, toda vez que We Chamber no
          garantiza que este funcione correctamente en cualquier dispositivo. De
          igual manera, el Usuario acepta no utilizar dispositivos, software o
          cualquier otro medio tendiente a interferir tanto en las actividades
          y/u operaciones del Sitio, como en las bases de datos y/o a la
          informaci&oacute;n que se contenga en el mismo.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          DURACI&Oacute;N Y TERMINACI&Oacute;N
        </p>
        <p>
          El Sitio y los Contenidos tienen una duraci&oacute;n indefinida. Sin
          embargo, We Chamber&nbsp;podr&aacute; dar por terminado o suspender
          temporalmente, en cualquier momento y sin necesidad de previo aviso,
          el Sitio y/o cualquiera de los Contenidos.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">OTRAS DISPOSICIONES</p>
        <p>
          El Usuario acepta que una versi&oacute;n impresa del presente Aviso
          Legal, y de cualquier comunicaci&oacute;n enviada y/o recibida en
          forma electr&oacute;nica, ser&aacute; admisible como medio probatorio
          en cualquier procedimiento judicial y/o administrativo.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">DIVISIBILIDAD</p>
        <p>
          En caso de que cualquier t&eacute;rmino, condici&oacute;n o
          estipulaci&oacute;n contenida en el presente Aviso Legal se determine
          ineficaz, ilegal o sin efecto, el mismo podr&aacute; ser excluido del
          cuerpo del presente y el restante continuar&aacute; en vigor y efecto
          en forma tan amplia como en derecho proceda.
        </p>
        <p><br /></p>
        <p>
          DERECHOS<br />Cualquier derecho que no se haya conferido expresamente
          en este documento, se entiende reservado a We Chamber.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">ACTUALIZACIONES&nbsp;</p>
        <p>
          We Chamber podr&aacute; revisar y actualizar, en cualquier momento, el
          presente Aviso Legal, manteniendo en todo momento el acceso libre a
          todo Usuario que desee conocerlo. We Chamber&nbsp;se reserva el
          derecho de modificar, en cualquier momento, la presentaci&oacute;n y
          configuraci&oacute;n del Sitio, as&iacute; como el presente Aviso
          Legal. Por ello, We Chamber&nbsp;recomienda al Usuario dar lectura
          atenta cada vez que acceda al Sitio. No obstante lo anterior, el
          Usuario siempre dispondr&aacute; este Aviso Legal en el Sitio, de
          forma visible y accesible en cualquier momento.&nbsp;Algunas
          cl&aacute;usulas de este Aviso Legal&nbsp;pueden estar supeditadas a
          t&eacute;rminos y condiciones designados expresamente y que se
          encuentren en el SItio o en determinados sitios web.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          LEY APLICABLE Y JURISDICCI&Oacute;N
        </p>
        <p>
          En todo lo relacionado con la interpretaci&oacute;n y cumplimiento de
          lo aqu&iacute; dispuesto, por el solo hecho de acceder al Sitio, el
          Usuario acepta someterse a las legislaci&oacute;n federal de
          M&eacute;xico y a la jurisdicci&oacute;n de los tribunales competentes
          en la Ciudad de Le&oacute;n, Guanajuato, M&eacute;xico; renunciando a
          cualquier otra jurisdicci&oacute;n que, por raz&oacute;n de sus
          domicilios presentes o futuros, pudiese corresponderles.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">ACEPTACI&Oacute;N DEL AVISO LEGAL</p>
        <p>
          El Usuario reconoce que, mediante el ingreso y uso del Sitio,
          manifiesta su aceptaci&oacute;n expresa y adhesi&oacute;n al presente
          Aviso Legal, en&nbsp;la versi&oacute;n publicada al momento en que se
          acceda al Sitio, en t&eacute;rminos de lo establecido por los
          art&iacute;culos 1803 y 1834 Bis del C&oacute;digo Civil Federal, 80,
          81, 89 y dem&aacute;s relativos y aplicables del C&oacute;digo de
          Comercio y la legislaci&oacute;n aplicable para la Rep&uacute;blica
          Mexicana.&nbsp;Es responsabilidad &uacute;nica y exclusiva del
          Usuario, leer previamente este Aviso Legal, y sus modificaciones
          correspondientes, cada vez que accede al Sitio, por lo que si en
          cualquier momento, el Usuario no estuviera de acuerdo, total o
          parcialmente con el presente Aviso Legal, deber&aacute; abstenerse
          inmediatamente de acceder al Sitio y su Contenido.&nbsp;Por lo
          anterior, con la aceptaci&oacute;n del presente Aviso Legal, el
          Usuario consiente expresamente sujetarse al mismo, por lo que
          manifiesta haber le&iacute;do el contenido de todas y cada una de las
          disposiciones y ratifica su contenido.
        </p>
        <p><br /></p>
      </v-col>
      <v-col
        cols="10"
        offset="1"
        md="8"
        offset-md="2"
        class="text-center caption"
      >
        <p>
          Fecha de primera emisi&oacute;n: 22 de noviembre de 2022. Fecha de
          &uacute;ltima modificaci&oacute;n: 22 de noviembre de 2022.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>